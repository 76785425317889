import { useEffect } from "react";

export function useCloseOnClickOutside(
  fn: () => void,
  ...refs: Array<React.RefObject<HTMLDivElement>>
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        refs.every((r) => {
          return (
            r.current && !r.current.contains(event.target as HTMLDivElement)
          );
        })
      ) {
        fn();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs]);
}
